<template>
  <div
    class="flex flex-col mx-auto items-start bg-offwhite py-16 medium:px-36 mobile:px-4"
  >
    <div
      class="flex flex-col items-start mobile:text-center mobile:mb-8 w-3/4 px-16 mx-auto"
    >
      <div class="flex">
        <span class="text-5xl font-extrabold">
          Gewodo aitab töö tehtud saada.
        </span>
      </div>
      <div
        class="flex text-5xl font-extrabold mobile:text-center mobile:self-center"
      >
        <span class="text-green">Lihtsalt</span>.
      </div>
    </div>
    <div class="flex text-2xl w-3/4 mx-auto px-16 text-darkgrey">
      Kas Sul on õhtul koju jõudes vaja teha jälle 10 arvet ja 15 pakkumist ja
      üldse viitsimist ei ole? Töötajate tunnilehed näitavad alati "9-17" aga
      ülevaade Sul puudub? Või on kliendi saadetud fail jälle kuhugi
      meilirägastikku kadunud?
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      email: "",
      success: false,
      error: false,
      showVideo: false
    };
  },
  mixins: [RequestHandler],
  methods: {
    sendEmail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.loading = true;
        const data = { sender_email: this.email, type: "user" };
        this.apiRequest("core/email/interest/", "post", false, data)
          .then(
            res => {
              this.$store.state.loading = false;
              if (res.status === 201) {
                this.success = true;
              } else {
                this.error = true;
              }
            },
            err => {
              this.$store.state.loading = false;
              if (err) {
                this.error = true;
              }
            }
          )
          .catch(err => {
            this.$store.state.loading = false;
            if (err) {
              this.error = true;
            }
          });
      }
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
};
</script>
