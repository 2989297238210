<template>
  <div class="wrapper">
    <div class="container-full flex-col">
      <jumbotron class="w-full"></jumbotron>
      <process-widget></process-widget>
      <process class="w-full"></process>
      <how-does-it-look></how-does-it-look>
      <partner-feedback></partner-feedback>
      <partners></partners>
      <easy-to-use></easy-to-use>
      <in-numbers></in-numbers>
      <pricing></pricing>
      <dont-wait></dont-wait>
      <contact></contact>
    </div>
  </div>
</template>

<script>
import Jumbotron from "@/components/public/landing/Jumbotron";
import ProcessWidget from "@/components/public/landing/ProcessWidget";
import Process from "@/components/public/Process";
import HowDoesItLook from "@/components/public/HowDoesItLook";
import EasyToUse from "@/components/public/EasyToUse";
import Partners from "@/components/public/landing/Partners";
import PartnerFeedback from "@/components/public/PartnerFeedback";
import InNumbers from "@/components/public/landing/InNumbers";
import Pricing from "@/components/public/Pricing";
import DontWait from "@/components/public/DontWait";
import Contact from "@/components/public/Contact";

export default {
  components: {
    Jumbotron,
    ProcessWidget,
    Process,
    HowDoesItLook,
    Partners,
    EasyToUse,
    PartnerFeedback,
    InNumbers,
    Pricing,
    DontWait,
    Contact
  }
};
</script>
